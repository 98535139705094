@import "abstracts/_abstracts";
.QuickSearch {
    $block: &;
    color: var(--colorText);
    font-size: var(--textXs);
    align-self: stretch;

    @include media('<=md') {
        font-size: 1em;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: var(--layerModalZIndex);
        background: var(--colorShadeBg);
        pointer-events: none;
        transition: var(--animationBase);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;
        opacity: 0;
        padding: var(--spaceSm);
        perspective: 250px;

        &.is-active {
            pointer-events: auto;
            opacity: 1;
        }
    }

    &-content {
        position: relative;
        z-index: var(--layerPageSecondaryZIndex);
        height: 100%;

        @include media('<=md') {
            transform: translateY(80%) rotateX(-110deg) translateZ(-200px) scale(0.8);
            transform-origin: top center;
            transition: all 0.4s cubic-bezier(.64, -.19, .23, 1.33);
            position: relative;
            max-width: 100%;
            margin: auto;
            opacity: 0;
            height: auto;
            width: 50rem;
        }

        #{$block}.is-active & {
            @include media('<=md') {
                transform: translateY(0) rotateX(0) translateZ(0) scale(1);
                opacity: 1;
            }
        }
    }

    &-search {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;

        @include media('<=md') {
            background-color: var(--colorThemeAccent);
            padding: 1.2em 1.5em;
        }
    }

    &-icon {
        order: 1;

        @include media('>md') {
            position: absolute;
            top: 50%;
            right: 1.2em;
            width: 1.5em;
            height: auto;
            color: var(--colorBrand);
            transform: translateY(-50%);
            order: unset;
        }
    }

    &-input {
        flex: 1;
        line-height: 1.5;
        outline: none;
        font-size: 1rem;
        min-width: 20rem;
        color: var(--colorText);
        background: transparent;
        padding: .6em calc(var(--spaceSm) + 2.4em) .6em 1em;
        border: .125rem solid transparent;
        background: var(--colorSecondaryBg);
        border-radius: .5rem;
        transition: var(--animationBase);
        height: 100%;
        min-height: 3.5rem;

        @include media('<=md') {
            padding: 0;
            border: 0;
            background: #fff;
            order: 2;
            margin: 0 var(--spaceSm);
            min-height: unset;
            border-radius: 0;
            min-width: auto;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }

        &::placeholder {
            color: #999;
        }

        &:focus {
            border-color: var(--colorBrand);

            & + #{$block}-icon {
                stroke: currentColor;
                stroke-width: 20px;
            }
        }
    }

    &-cancel {
        cursor: pointer;
        transition: var(--animationBase);
        display: none;
        margin-left: var(--spaceSm);
        @include media('<=md') {
            display: block;
            order: 3;
            margin: 0;
        }

        svg {
            display: block;
            width: 1.2em;
            height: auto;
        }

        &:hover {
            color: var(--colorBrand);
        }
    }

    &-results {
        overflow: auto;
        max-height: calc(100vh - 200px);
        position: absolute;
        left: 0;
        width: 100%;
        top: 100%;
        background: var(--colorThemeAccent);
        box-shadow: 0 0 20px #0000004d;

        @include media('<=md') {
            max-height: calc(100vh - 120px);
            position: static;
            box-shadow: none;
            border-top: 1px solid #ededed;
        }
    }

    &-noResults {
        padding: 1em 1.8em;
        font-size: var(--textSm);
        font-weight: 600;
        color: var(--colorErrorsDefault);
    }

    &-title {
        font-weight: 700;
        padding: .8rem 1rem .2rem;

        &:after {
            content: ' ';
            display: block;
            width: 1.7em;
            height: 2px;
            background: var(--colorBrand);
            margin-top: .15em;
        }
    }
}

.Search-btn {
    padding: var(--spaceXs);
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: none;

    @include media('<=md') {
        display: block;
    }

    svg {
        color: #dbdbdb;
        @include media('<=sm') {
            color: #0095ff;
        }
    }
}

